export function today() {
    return new Date().toISOString().slice(0, 10);
}


export function getMonday() {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when today is Sunday
    const monday = new Date(now.setDate(diff));
    return monday
}

export function getFriday() {
    const monday = getMonday();
    const friday = new Date(monday);
    friday.setDate(monday.getDate() + 4);
    return friday
}

export function getMondayString() {
    const monday = getMonday()
    let datestring = monday.toISOString().slice(0, 10);
    console.log(datestring)
    return datestring
}


export function getFridayString() {
    const friday = getFriday()
    let datestring = friday.toISOString().slice(0, 10);
    console.log(datestring)
    return datestring
}

export function sleep(ms) {
    return new Promise(resolve => {
        setTimeout(() => { resolve('') }, ms);
    })  // This timeout is needed to avoid a bug in the viewer
}