import { backend_api_v2 } from '/src/utils/apiv2'
import base from "./base"
import { defineStore } from 'pinia'
import { useCompanyStore } from "/src/stores/company"
import { useSelectedStore } from "/src/stores/selected"
import { useSessionStore } from "/src/stores/session"
// import bus from '/src/utils/event_bus'
// import router from '/src/router'

// THIS MODULE SHOULD HOLD PROJECT/STDLIB CONSTUCTIBLES. NOT MORE NOT LESS.

function constructible_sorting_callback(a, b) {
    const session_store = useSessionStore()
    let sta = session_store.my_selected_station

    // First of all, readies go last, no readies first.
    if ((a.stations_status[sta] != "ready" && a.status != "ready") &&
        (b.stations_status[sta] == "ready" || b.status == "ready")) {
        return -1
    }
    if (
        (a.stations_status[sta] == "ready" || a.status == "ready") &&
        (b.stations_status[sta] != "ready" && b.status != "ready")) {
        return 1
    }

    // Compare dates then
    const dateA = new Date(a.scheduled_prod_date);
    const dateB = new Date(b.scheduled_prod_date);
    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;

    // If dates are equal, and status equivalent, compare priorities
    return a.priority - b.priority;
}

export const useConstructiblesStore = defineStore('constructibles', {
    state: () => ({
        // Designs
        module_designs: [],
        assembly_designs: [],
        part_designs: [],
        // STDLIB designs
        stdlib_module_designs: [],
        stdlib_assembly_designs: [],
        // Actuals
        batches: [],
        modules: [],
        assemblies: [],
        parts: [],
    }),
    getters: {   // LISTS
        allDesigns: state => [...state.module_designs, ...state.assembly_designs,],
        allActuals: state => [...state.modules, ...state.assemblies,],
        myList() {
            return function (level) {
                const company_store = useCompanyStore()
                let actuals = this.annotatedActualsFromLevel(level)
                const by_date = company_store.company?.work_by_date
                if (by_date) {
                    const my_batches_uuids = this.myBatches.map(b => b.uuid)

                    // Sorted straight by priority (no repeated numbers for single date).
                    return actuals
                        .filter(a => my_batches_uuids.includes(a.batch))
                        .sort(constructible_sorting_callback)
                } else {
                    // Sorted by phase-batch-priority.
                    let sorted_list = []
                    this.myBatches.forEach(batch => {
                        sorted_list.push(
                            ...actuals.filter(con => con.batch == batch.uuid)
                                .sort(constructible_sorting_callback)
                        )
                    })
                    return sorted_list
                }
            }
        },
        myFirst: () => function (level) {
            const session_store = useSessionStore()
            let sta = session_store.my_selected_station
            let list = this.myList(level).filter(con => con.stations_status[sta] != "ready" && con.status != "ready")
            if (list.length != 0)
                return list[0]
            else return false
        },
        myListNoFirst: () => function (level) {
            const session_store = useSessionStore()
            let sta = session_store.my_selected_station
            let first = this.myFirst(level)
            let no_first = this.myList(level)
                .filter(con => con.uuid != first.uuid)
            let futures = no_first
                .filter(con => con.stations_status[sta] != "ready" && con.status != "ready")
            let readies = no_first
                .filter(con => con.stations_status[sta] == "ready" || con.status == "ready")
            const my_list = [...futures, ...readies]
            return my_list
        },
        annotatedActualsFromLevel: (state) => (level) => {
            if (level == "assembly") {
                return state.assemblies.map(assd => ({ ...assd, level: "assembly" }));
            } else if (level == "module") {
                return state.modules.map(modd => ({ ...modd, level: "module" }));
            } else {
                return [
                    ...state.modules.map(modd => ({ ...modd, level: "module" })),
                    ...state.assemblies.map(assd => ({ ...assd, level: "assembly" }))
                ];
            }
        },
        // This annotates the class, for when you mix both designs in same list.
        annotatedDesignsFromLevel: (state) => (level) => {
            if (level == "assembly") {
                return state.assembly_designs.map(assd => ({ ...assd, level: "assembly" }));
            } else if (level == "module") {
                return state.module_designs.map(modd => ({ ...modd, level: "module" }));
            } else {
                return [
                    ...state.module_designs.map(modd => ({ ...modd, level: "module" })),
                    ...state.assembly_designs.map(assd => ({ ...assd, level: "assembly" }))
                ];
            }
        },
        annotatedDesignsFromLevelOnlyLastRevisions: () => function (level) {
            let designs = this.annotatedDesignsFromLevel(level)

            // Use reduce to create a mapping of names to objects with the highest revision
            const result = designs.reduce((acc, obj) => {
                if (!acc[obj.name] || acc[obj.name].revision < obj.revision) {
                    acc[obj.name] = obj;
                }
                return acc;
            }, {});

            // Convert the result object back to an array
            return Object.values(result);
        },

        // Batch and batch state
        myBatches: (state) => {
            const session_store = useSessionStore()
            const selected_store = useSelectedStore()
            let user_object = session_store.user_object;
            let phases = selected_store.selected_project?.phases || []
            let production_line = user_object
                ? user_object.auth?.selected_production_line
                : null;
            let batches = [...state.batches]
            if (production_line) {
                batches = batches.filter((b) => b.production_line == production_line);
            }
            batches.sort((a, b) => {
                // Sort by phase and batch
                let idx_phase_a = phases.indexOf(a.phase)
                let idx_phase_b = phases.indexOf(b.phase)
                if (idx_phase_a == idx_phase_b) return a.priority - b.priority
                else return idx_phase_a - idx_phase_b
            })
            return batches
        },


        // BY UUID GETTERS
        designNameByUuid() {
            const allDesigns = this.allDesigns
            return function (uuid, include_rev = true) {
                let d = allDesigns.find((d) => d.uuid == uuid)
                if (include_rev) return `${d ? d?.name : ""} rev ${d?.revision}`
                else return `${d ? d?.name : ""}`
            }
        },
        getLevelFromActualUuid: () => function (uuid) {
            let actual = this.annotatedActualsFromLevel("all")
                .find(actual => actual.uuid == uuid)
            return actual?.level || "unknown"
        },


        // Boolean flags
        haveModules: state => state.modules.length != 0,
        haveModuleDesigns: state => state.module_designs.length != 0,
        haveAssemblies: state => state.assemblies.length != 0,
        haveAssemblyDesigns: state => state.assembly_designs.length != 0,
        isBatchReadyByUuid() {
            const allActuals = this.allActuals
            return function (uuid) {
                let locked = true;
                let actuals = allActuals.filter(actual => actual.batch == uuid)
                if (actuals.length == 0) return false
                actuals.forEach((actual) => {
                    if (actual.status != "ready") locked = false;
                });
                return locked;
            }
        },
    },
    actions: {
        // Designs
        setModuleDesigns: base.actions.setter("module_designs"),
        createModuleDesign: base.actions.creator("module_designs"),
        updateModuleDesign: base.actions.updater("module_designs"),
        removeModuleDesign: base.actions.remover("module_designs"),

        setAssemblyDesigns: base.actions.setter("assembly_designs"),
        createAssemblyDesigns: base.actions.creator("assembly_designs"),
        updateAssemblyDesign: base.actions.updater("assembly_designs"),
        removeAssemblyDesigns: base.actions.remover("assembly_designs"),

        setPartDesigns: base.actions.setter("part_designs"),
        createPartDesign: base.actions.creator("part_designs"),
        updatePartDesign: base.actions.updater("part_designs"),
        removePartDesign: base.actions.remover("part_designs"),

        // Actuals
        setBatches: base.actions.setter("batches"),
        createBatch: base.actions.creator("batches"),
        updateBatch: base.actions.updater("batches"),
        removeBatch: base.actions.remover("batches"),

        setModules: base.actions.setter("modules"),
        createModule: base.actions.creator("modules"),
        updateModule: base.actions.updater("modules"),
        removeModule: base.actions.remover("modules"),

        setAssemblies: base.actions.setter("assemblies"),
        createAssembly: base.actions.creator("assemblies"),
        updateAssembly: base.actions.updater("assemblies"),
        removeAssembly: base.actions.remover("assemblies"),

        setParts: base.actions.setter("parts"),
        createPart: base.actions.creator("parts"),
        updatePart: base.actions.updater("parts"),
        removePart: base.actions.remover("parts"),


        // GENERAL MULTIPLE DELETION
        multiDeleteByUuids(uuids) {
            // Delete from all lists
            const store_keys = [
                "module_designs",
                "assembly_designs",
                "part_designs",
                "stdlib_module_designs",
                "stdlib_assembly_designs",
                "batches",
                "modules",
                "assemblies",
                "parts",
            ]
            store_keys.forEach((k) => this[k] = this[k].filter(d => !uuids.includes(d.uuid)))
        },

        multiUpdaterByUuids(uuids, payload) {
            const store_keys = [
                "module_designs",
                "assembly_designs",
                "part_designs",
                "stdlib_module_designs",
                "stdlib_assembly_designs",
                "batches",
                "modules",
                "assemblies",
                "parts",
            ]
            store_keys.forEach((k) => {
                let items = this[k]
                items.forEach((item) => {
                    if (uuids.includes(item.uuid)) {
                        Object.assign(item, payload)
                    }
                })
            })
        },


        // Designs backend actions
        loadModuleDesigns: base.actions.loader_pr("constructibles/module_designs/", "ModuleDesigns"),
        loadModuleDesignsByDate: base.actions.loader_date("constructibles/module_designs/", "ModuleDesigns"),
        postModuleDesign: base.actions.poster("constructibles/module_designs/", "ModuleDesign"),
        putModuleDesign: base.actions.putter("constructibles/module_designs/", "ModuleDesign"),
        deleteModuleDesign: base.actions.deleter("constructibles/module_designs/", "ModuleDesign"),
        refreshModuleDesign: base.actions.refresher("constructibles/module_designs/", "ModuleDesign"),
        refreshModuleDesigns: base.actions.multi_refresher("constructibles/module_designs/", "ModuleDesign"),

        loadAssemblyDesigns: base.actions.loader_pr("constructibles/assembly_designs/", "AssemblyDesigns"),
        loadAssemblyDesignsByDate: base.actions.loader_date("constructibles/assembly_designs/", "AssemblyDesigns"),
        postAssemblyDesign: base.actions.poster("constructibles/assembly_designs/", "AssemblyDesign"),
        putAssemblyDesign: base.actions.putter("constructibles/assembly_designs/", "AssemblyDesign"),
        deleteAssemblyDesign: base.actions.deleter("constructibles/assembly_designs/", "AssemblyDesign"),
        refreshAssemblyDesign: base.actions.refresher("constructibles/assembly_designs/", "AssemblyDesign"),
        refreshAssemblyDesigns: base.actions.multi_refresher("constructibles/assembly_designs/", "AssemblyDesign"),


        // FOR NOW PART DESIGNS ARE NOT USED DIRECTLY
        // loadPartDesigns: base.actions.loader_pr("constructibles/part_designs/", "PartDesigns"),
        // loadPartDesignsByDate: base.actions.loader_date("constructibles/part_designs/", "PartDesigns"),
        // postPartDesign: base.actions.poster("constructibles/part_designs/", "PartDesign"),
        // putPartDesign: base.actions.putter("constructibles/part_designs/", "PartDesign"),
        // deletePartDesign: base.actions.deleter("constructibles/part_designs/", "PartDesign"),

        // Multiple Actions for Designs
        multiDeleteDesigns(uuids) {
            return backend_api_v2.post('constructibles/designs/bulk_delete/', uuids)
                .then(() => this.multiDeleteByUuids(uuids))
                .catch(e => { console.log(`Cant batch delete designs: ${e}`) })
        },
        launchAnalysisForDesigns(uuids) {
            return backend_api_v2.post('constructibles/designs/launch_analysis/', uuids)
                .then(() => console.log(`Launched analysis for: ${uuids}`))
                .catch(e => { console.log(`Cant launch analysis: ${e}`) })
        },

        // Actuals
        loadBatches: base.actions.loader_pr("constructibles/batches/", "Batches"),
        loadBatchesByDate: base.actions.loader_date("constructibles/batches/", "Batches"),
        postBatch: base.actions.poster("constructibles/batches/", "Batch"),
        putBatch: base.actions.putter("constructibles/batches/", "Batch"),
        deleteBatch: base.actions.deleter("constructibles/batches/", "Batch"),

        loadModules: base.actions.loader_pr("constructibles/modules/", "Modules"),
        loadModulesByDate: base.actions.loader_date("constructibles/modules/", "Modules"),
        postModule: base.actions.poster("constructibles/modules/", "Module"),
        putModule: base.actions.putter("constructibles/modules/", "Module"),
        deleteModule: base.actions.deleter("constructibles/modules/", "Module"),
        refreshModule: base.actions.refresher("constructibles/modules/", "Module"),
        refreshModules: base.actions.multi_refresher("constructibles/modules/", "Module"),

        loadAssemblies: base.actions.loader_pr("constructibles/assemblies/", "Assemblies"),
        loadAssembliesByDate: base.actions.loader_date("constructibles/assemblies/", "Assemblies"),
        postAssembly: base.actions.poster("constructibles/assemblies/", "Assembly"),
        putAssembly: base.actions.putter("constructibles/assemblies/", "Assembly"),
        deleteAssembly: base.actions.deleter("constructibles/assemblies/", "Assembly"),
        refreshAssembly: base.actions.refresher("constructibles/assemblies/", "Assembly"),
        refreshAssemblies: base.actions.multi_refresher("constructibles/assemblies/", "Assembly"),

        loadParts: base.actions.loader_pr("constructibles/parts/", "Parts"),
        loadPartsByDate: base.actions.loader_date("constructibles/parts/", "Parts"),
        postPart: base.actions.poster("constructibles/parts/", "Part"),
        putPart: base.actions.putter("constructibles/parts/", "Part"),
        deletePart: base.actions.deleter("constructibles/parts/", "Part"),

        // Multiple Actions for Actuals
        multiDeleteActuals(uuids) {
            return backend_api_v2.post('constructibles/actuals/bulk_delete/', uuids)
                .then(() => this.multiDeleteByUuids(uuids))
                .catch(e => { console.log(`Cant batch delete actuals: ${e}`) })
        },
        multiPatchActuals(payload) {
            return backend_api_v2.post('constructibles/actuals/bulk_patch/', payload)
                .then(() => this.multiUpdaterByUuids(payload.uuids, payload.payload))
                .catch(e => { console.log(`Cant batch delete actuals: ${e}`) })
        },

        // Others
        loadMyFirstConstructible(project_uuid) {
            return backend_api_v2.get('company/projects/' + project_uuid + '/first_constructible/')
                .then(({ data }) => {
                    if (data.is_a == "Assembly")
                        this.setAssemblies([data.assembly_list_object])
                    else if (data.is_a == "Module")
                        this.setModules([data.module_list_object])
                })
                .catch(e => { console.log(`Cant load first constructible: ${e}`) })
        },

    },
})
