<template>
    <SelectorProductionDate class="mx-auto mb-1" :wide="true" v-if="show_by_date_manager" />
    <ListSelector v-if="show_list_selector" />
    <!-- <v-divider v-if="show_list_selector" /> -->
</template>


<script setup>
import SelectorProductionDate from "@/components/left_menu/SelectorProductionDate.vue";
import ListSelector from "@/components/left_menu/list/ListSelector.vue";

import { useSessionStore } from "@/stores/session";
import { useCompanyStore } from "@/stores/company";
import { useSelectedStore } from "@/stores/selected";
import { computed } from "vue";

const session_store = useSessionStore()
const company_store = useCompanyStore()
const selected_store = useSelectedStore()

const company_wide = computed(() => selected_store.selected_project?.uuid == "all")
const company = computed(() => company_store.company)
const prod_worker = computed(() => session_store.iam_prod_worker)
const show_list_selector = computed(() => !prod_worker.value)
const show_by_date_manager = computed(() => !prod_worker.value && company.value?.work_by_date && company_wide.value)


</script>